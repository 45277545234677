<template>
    <div class="about">
        <section id="cms-content" class="cms-despre-noi">
            <div class="container">
                <div class="cms-content">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="section-title">{{ $t('privacy-policy.html.title') }}</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="entry-content">
                                <!-- articolele: 1 -->
                                <div v-html="$t('privacy-policy.html.p1')"></div>
                                <!-- articolele: 2 - 2.1 -->
                                <div v-html="$t('privacy-policy.html.p2')"></div>
                                <!-- articolele: 2.2 -->
                                <div v-html="$t('privacy-policy.html.p3')"></div>
                                <!-- articolele: 2.3 -->
                                <div v-html="$t('privacy-policy.html.p4')"></div>
                                <!-- articolele: 2.4 -->
                                <div v-html="$t('privacy-policy.html.p5')"></div>
                                <!-- articolele: 3 -->
                                <div v-html="$t('privacy-policy.html.p6')"></div>
                                <!-- articolele: 4 -->
                                <div v-html="$t('privacy-policy.html.p7')"></div>
                                <!-- articolele: 5 -->
                                <div v-html="$t('privacy-policy.html.p8')"></div>
                                <!-- articolele: 6 -->
                                <div v-html="$t('privacy-policy.html.p9')"></div>
                                <!-- articolele: 7 - 9 -->
                                <div v-html="$t('privacy-policy.html.p10')"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="becomepartner">
            <div class="container">
                <div class="row">
                    <div class="col-md-7 d-flex">
                        <div id="partner-text">
                            <h3>{{ $t('home.partner.title') }}</h3>
                            <p>{{ $t('home.partner.content') }}</p>
                            <a href="https://shop.koff.ro/register" target=”_blank” class="btn btn-blue ripple">{{ $t('home.partner.register-button') }}</a>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div id="partner-img">
                            <img src="../assets/img/img-partners.jpg" class="img-responsive" alt="parteneri koff">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
</script>

<style lang="scss">
    @import '../assets/scss/cms.scss';

    #cms-content .entry-content strong {
        font-weight: bold!important;
    }

</style>
